body, html {
  font-family: 'Arial', sans-serif;
}

.soothing-background {
  background: linear-gradient(135deg, #5fb4ff, #feb47b);
  background-size: 400% 400%;
  animation: gradientAnimation 15s ease infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* CSS for stopwatch */
.stopwatch-time {
  font-family: monospace, monospace, 'Arial', sans-serif;
  text-align: center;
  font-size: 60px;
}

/* General utility CSS classes for app */

.spinning {
  animation: spin infinite 2s linear;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.zoomable-subtle {
  transition: transform .2s;
}
.zoomable-subtle:hover {
  transform: scale(1.015);
}
.zoomable-mid {
  transition: transform .2s;
}
.zoomable-mid:hover {
  transform: scale(1.05);
}
.zoomable {
  transition: transform .2s;
}
.zoomable:hover {
  transform: scale(1.1);
}

.not-too-wide {
  max-width: 500px;
}
.not-obscenely-wide {
  max-width: 850px;
}

.clickable {
  cursor: pointer;
}

.balanced-text {
  text-wrap: balance;
}

